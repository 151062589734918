import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Checkbox, CheckboxGroup, CheckboxDisclaimer } from '@twilio-paste/checkbox';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import { Anchor } from '@twilio-paste/anchor';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/checkbox/CHANGELOG.md';
import { defaultExample, helpTextExample, indeterminateExample, errorExample, requiredExample, disabledExample } from '../../../component-examples/CheckboxExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/checkbox"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/checkbox/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Checkbox" categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/checkbox" storybookUrl="/?path=/story/components-checkbox--checkbox" abstractUrl="https://share.goabstract.com/6cc5fc40-3408-4cfd-b1d0-103aea01601d" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h2>{`Checkbox and Checkbox Group`}</h2>
        <h3>{`About Checkbox Group`}</h3>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <p>{`Checkbox groups are used when you have multiple binary choices to make but all choices belong to a single group or field. For example, choosing capabilities when searching for a Phone Number to provision from Twilio. By placing them in a group, users should be able to clearly understand which options make up the collection and also how those options are related to each other.`}</p>
        <h4>{`Accessibility Information`}</h4>
        <ul>
          <li parentName="ul">{`Checkbox groups `}<strong parentName="li">{`must`}</strong>{` have a group legend that describes the collection.`}<ul parentName="li">
              <li parentName="ul">{`The label `}<em parentName="li">{`should`}</em>{` be visible.`}</li>
              <li parentName="ul">{`The label may be visually hidden or provided via `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` on the group component if the entire form is just a single checkbox group with no other form elements. The grouping should be visually implicit, but a description still needs to be provided for assistive technology.`}</li>
            </ul></li>
        </ul>
        <h3>{`About Checkbox`}</h3>
        <p>{`Use a checkbox to present a user with a binary (e.g. on/off) choice that is part of a form. A checkbox should not be used to apply a user's choice instantly. In that case, such as enabling a system feature, you should consider using the toggle switch component (being built in a coming sprint).`}</p>
        <h4>{`Accessibility Information`}</h4>
        <ul>
          <li parentName="ul">{`A checkbox must have a visible label that is in close proximity to the control`}</li>
          <li parentName="ul">{`HTML checkboxes don't natively support the `}<inlineCode parentName="li">{`required`}</inlineCode>{` attribute. In cases where a checkbox is required to be checked:`}<ul parentName="li">
              <li parentName="ul">{`Display a required indicator`}</li>
              <li parentName="ul">{`Ensure the label text includes wording that successfully describes the requirement to the user that they should check the box`}</li>
            </ul></li>
          <li parentName="ul">{`When in an error state display an inline error message below the offending checkbox that clearly describes the error along with an icon that depicts the severity.`}</li>
          <li parentName="ul">{`When displaying additional content based on checking a checkbox, be sure that the new content appears after the checkbox so that it is naturally discoverable by assistive technology users.`}</li>
        </ul>
        <h2>{`Examples`}</h2>
        <h3>{`Basic checkbox with label`}</h3>
        <p>{`A checkbox is always displayed next to a visible label.`}</p>
        <LivePreview scope={{
          Checkbox
        }} language="jsx" mdxType="LivePreview">
  {`<>
  <Checkbox checked={true} id="blm" value="blm" name="blm">
    Black lives matter
  </Checkbox>
</>`}
        </LivePreview>
        <h3>{`Checkbox with help text`}</h3>
        <p>{`In cases where the checkbox requires additional context, you can display this information as help text below the checkbox control and label. This can help keep checkbox labels concise.`}</p>
        <LivePreview scope={{
          Checkbox
        }} language="jsx" mdxType="LivePreview">
  {`<>
  <Checkbox id="enabled" value="enabled" name="enabled" helpText="Determines if certificate validation is performed on all Twilio originated requests">
    Enable SSL Certificate Validation
  </Checkbox>
</>`}
        </LivePreview>
        <h3>{`Required checkbox`}</h3>
        <p>{`When a checkbox is required to be checked, a required indicator should be displayed alongside the label. The label text should also be written in such a way that this requirement is clear to the user.`}</p>
        <LivePreview scope={{
          Checkbox
        }} language="jsx" mdxType="LivePreview">
  {`<>
  <Checkbox id="delete" value="delete" name="delete" required>
    Confirm this message should be deleted
  </Checkbox>
</>`}
        </LivePreview>
        <h3>{`Checkbox group`}</h3>
        <p>{`Multiple checkboxes and their labels are grouped together with a common group component. The group legend must be the first element inside the group. It must appear before any checkboxes or other content.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Checkbox group with help text`}</h3>
        <p>{`You can provide additional information about the group with the use of help text. Help text can appear after the group label but before the first group member. Each item in the group may also provide their own, individual help text.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup,
          Anchor,
          Text
        }} noInline mdxType="LivePreview">
  {helpTextExample}
        </LivePreview>
        <h3>{`Checkbox Disclaimer`}</h3>
        <LivePreview scope={{
          CheckboxDisclaimer,
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<>
  <CheckboxDisclaimer id="disclaimer" value="disclaimer" name="disclaimer">
    <Text as="span">
      I declare the information provided above is accurate. I acknowledge that Twilio will process the information
      provided above for the purpose of identity verification, and will be sharing it with my local telecomm
      providers or authorities where required by local law. I understand that Twilio phone numbers may be taken out
      of service for inaccurate or false information.
    </Text>
  </CheckboxDisclaimer>
</>`}
        </LivePreview>
        <h2>{`States`}</h2>
        <h3>{`Unchecked, checked and indeterminate`}</h3>
        <p>{`The default state of a checkbox indicates that the control is unchecked, or not selected. When a checkbox is clicked or the spacebar is pressed when focused, the checkbox will become checked. Doing so again will place the checkbox back into the unchecked state. A checkbox can be placed into a pre-checked state by setting the `}<inlineCode parentName="p">{`checked`}</inlineCode>{` property.`}</p>
        <p>{`The third state a checkbox can appear in is the indeterminate state. This is to indicate that a checkbox is neither checked nor unchecked. This is useful when dealing with related groups of checkboxes that have a dependent relationship, for example, a select all feature.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup
        }} noInline mdxType="LivePreview">
  {indeterminateExample}
        </LivePreview>
        <h3>{`Disabled checkbox`}</h3>
        <p>{`Use a disabled checkbox to indicate that a particular option cannot be interacted with or can be safely ignored.`}</p>
        <LivePreview scope={{
          Checkbox
        }} language="jsx" mdxType="LivePreview">
  {`<>
  <Checkbox
    id="hammer"
    value="hammer"
    name="hammer"
    disabled
    checked
  >
    Can't touch this
  </Checkbox>
</>`}
        </LivePreview>
        <h3>{`Required checkbox group`}</h3>
        <p>{`When at least one item from the checkbox group is required, a required indicator should be displayed alongside the group legend. The group help text should be used to describe the requirement.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup
        }} noInline mdxType="LivePreview">
  {requiredExample}
        </LivePreview>
        <h3>{`Disabled checkbox group`}</h3>
        <p>{`Use a disabled checkbox group to indicate that all options cannot be interacted with, with each checkbox individually disabled.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup
        }} noInline mdxType="LivePreview">
  {disabledExample}
        </LivePreview>
        <h3>{`Checkbox group with an inline error`}</h3>
        <p>{`If the selected options don't pass the group validation requirements, an inline error message should be displayed.`}</p>
        <p>{`An inline error is placed at the top of the group to inform a user of any errors in their choices. If help text exists, the error text should replace and repeat the help text.`}</p>
        <LivePreview scope={{
          Checkbox,
          CheckboxGroup
        }} noInline mdxType="LivePreview">
  {errorExample}
        </LivePreview>
        <h2>{`Composition notes`}</h2>
        <p>{`A checkbox must have a visible label.`}</p>
        <h3>{`Checkbox labels, legends and help text`}</h3>
        <p>{`A checkbox must have a visible label. A checkbox group must have a visible legend.`}</p>
        <p>{`Labels should clearly describe the value being requested. Legends should clearly describe the group. They should be concise and descriptive. To do this:`}</p>
        <ul>
          <li parentName="ul">{`Use help text to provide additional context about the option if needed.`}</li>
          <li parentName="ul">{`Avoid articles ("a", "the") and punctuation. For example, use "SIM registration code" rather than "The SIM registration code".`}</li>
        </ul>
        <p>{`Give users enough information in help text to prevent an error. Keep it concise and scoped to information that will help with validation. For example, use help text if a particular option has a long description necessary to inform the users choice.`}</p>
        <p>{`To support internationalization, avoid starting a sentence with the legend and using the field to finish it since sentence structures vary across languages. For example, use "Phone Number capabilities", not "Phone Number capabilities include:".`}</p>
        <h3>{`Validation and errors`}</h3>
        <p>{`Validate form fields on form submission. Don't validate each item in a group, treat validation on the group as a whole.`}</p>
        <p>{`Use inline error text to explain how to fix an error.`}</p>
        <p>{`Ideally, help text should have enough information to help users prevent errors. If help text exists and you need to show an error, the error text should replace and repeat the help text until the error has been resolved.`}</p>
        <p>{`Error text should:`}</p>
        <ul>
          <li parentName="ul">{`Be actionable. Explain how to fix an error and if reasonable, why it happened so that it might also be prevented in the future.`}</li>
          <li parentName="ul">{`Be concise and simple, maybe even more than normal. Avoid jargon. Try to keep error text to 2 sentences or fewer.`}</li>
          <li parentName="ul">{`Use the passive voice for input errors to avoid placing blame on the user. For example, "A Friendly Name is required."`}</li>
          <li parentName="ul">{`Use the active voice for system errors. For example, "Our systems are currently down. Please contact our support team.".`}</li>
        </ul>
        <h2>{`When to use a Checkbox and Checkbox Group`}</h2>
        <p>{`Use a checkbox when users are required to make a binary choice. Use a checkbox group when more than one checkbox is related to one another.`}</p>
        <p>{`There is no maximum number of checkboxes you can have in a group, but when it starts to get over 6 you might consider another component. Use your discretion to decide what is best for your use case. Some alternatives to look at when the number of options starts getting above 6 might be a Select or Combobox.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use checkboxes to enable multiple choice." mdxType="Do" />
  <Dont title="Don't" body="Don't use checkboxes when you need to restrict selection to a single option, use a radio group or select instead." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Save the choice the user made upon submission of the form." mdxType="Do" />
  <Dont title="Don't" body="Don't save or persist a user's choice upon checking a checkbox." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Keep help text and error text concise and simple. If you need to use more than 2 sentences to explain a field, link out to supporting docs or trigger a popover instead." mdxType="Do" />
  <Dont title="Don't" body="Don't use more than 2 sentences in help text or error text." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Include a visible label on every checkbox" mdxType="Do" />
  <Dont title="Don't" body="Don't leave floating, unlabelled checkboxes." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Write label text that clearly describes the value being requested." mdxType="Do" />
  <Dont title="Don't" body="Don't use the legend and label text in a way that is intended to be read as a sentence." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Include a visible legend for the entire group of checkboxes." mdxType="Do" />
  <Dont title="Don't" body="Don't leave floating, unlabelled checkbox groups." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Write legend text to describe a group and their intended relationship together." mdxType="Do" />
  <Dont title="Don't" body="Don't use a legend as a heading or section title." mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Provide actionable error text explaining how to fix the error." mdxType="Do" />
  <Dont title="Don't" body="Don't display system messages as error text or only what field errored." mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/checkbox - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Checkbox, CheckboxGroup} from '@twilio-paste/checkbox';

const Component = () => (
  <CheckboxGroup name="foo" legend="foo">
    <Checkbox id="foo" value="foo" name="foo">
      Foo
    </Checkbox>
  </CheckboxGroup>
);
`}</code></pre>
        <h4>{`Checkbox Props`}</h4>
        <p>{`All the valid HTML attributes for `}<inlineCode parentName="p">{`input[type=checkbox]`}</inlineCode>{` are supported including the following props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hasError?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helpText?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`indeterminate?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelectAll?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelectAllChild?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onChange?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onFocus?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onBlur?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`CheckboxGroup Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`legend`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`oneOf(['vertical', 'horizontal'])`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`vertical`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`errorText?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`helpText?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`required?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isSelectAll?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`CheckboxDisclaimer Props`}</h4>
        <p>{`All the valid HTML attributes for `}<inlineCode parentName="p">{`input[type=checkbox]`}</inlineCode>{` are supported including the following props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`errorText?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onChange?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onFocus?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onBlur?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      